<template>
  <b-card>
    <b-overlay
      opacity="0.5"
      :show="create_group_overlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col md="4">
          <b-form-group label="Nome" label-for="name">
            <b-form-input
              id="name"
              placeholder="Nome do grupo"
              v-model="group_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="8"></b-col>
        <b-col md="4" style="margin-top: 1rem">
          <b-form-group label="Descrição" label-for="description">
            <b-form-textarea
              id="description"
              placeholder="Descrição do grupo"
              rows="3"
              v-model="group_description"
            />
          </b-form-group>
        </b-col>
        <b-col md="8"></b-col>
        <b-col md="4">
          <div style="margin-top: 2.5vh; text-align: left">
            <b-button
              :disabled="create_group_overlay"
              @click="createGroup()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              block
            >
              Criar grupo
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal
      v-model="modalShow"
      ref="my-modal"
      id="modal-group_finish"
      hide-footer
      centered
      title="Grupo adicionado!"
      size="sm"
    >
      <b-row>
        <b-col cols="12">
          <p>Aproveite e adicione os primeiros membros e dispositivos!</p>
        </b-col>
      </b-row>

      <div style="margin-top: 2.5vh; text-align: right" @click="changeModal">
        <b-button
          style="margin-right: 1vw"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          to="/users"
          @click="changeModal"
        >
          <feather-icon icon="UserPlusIcon" class="mr-50" />
          <span class="align-middle">Membros</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          to="/devices/add"
          @click="changeModal"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Dispositivos</span>
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardText,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BCardGroup,
  BCol,
  BRow,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cookies from "js-cookie";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import api from "@/service/api";

export default {
  components: {
    BOverlay,
    BCard,
    BCardText,
    BButton,
    BFormInput,
    BFormTextarea,
    BCardGroup,
    BCol,
    BRow,
    VBModal,
    BModal,
    ToastificationContent,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  created() {
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }
    
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }
  },
  data() {
    return {
      create_group_overlay: false,
      modalShow: false,
      group_description: "",
      group_name: "",
    };
  },
  methods: {
    changeModal() {
      this.modalShow = !this.modalShow;
    },
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async createGroup() {
      var self = this;

      self.create_group_overlay = true;

      if (self.group_name == "") {
        self.showToast(
          "danger",
          "Notificação",
          "BellIcon",
          "O grupo precisa de um nome!"
        );
      } else {
        var data = {
          name: self.group_name,
          description: self.group_description,
        };

        let created_group = await api.post_group(data);
        if (created_group != []) {
          store.state.active_group = created_group.data;
          self.changeModal();
          self.showToast(
            "success",
            "Notificação",
            "BellIcon",
            "Grupo criado com sucesso!"
          );
        } else {
          self.showToast(
            "danger",
            "Erro",
            "BellIcon",
            "Ocorreu um erro ao tentar criar um grupo"
          );
        }

        store.state.group_exist = await api.get_groups();

        data = {
          name: "Dashboard de " + self.group_name,
          description: "",
          content: "",
          groupId: store.state.active_group.id,
        };

        await api.post_dashboard(data);
      }
      self.create_group_overlay = false;
    },
  },
};

if (Cookies.get("token_api") == undefined) {
  top.location.href = "https://tekie.work:8080/login";
}
</script>

<style>
</style>
